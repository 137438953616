import React from "react";
import { Button, Input, LinkButton, Select } from "../../../../components/Form";
import { usePreviousRoute } from "../../../../hooks/navigation";
import { UseUserProfiles } from "../hooks/useUserProfiles";
import { Navigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import styles from "./InsertAndUpdate.module.css";
import { Circle } from "../../../../components/Loading";

interface Props {
  data: UseUserProfiles;
  isUpdatePage?: boolean;
}

export default function InsertAndUpdate({ data, isUpdatePage = false }: Props) {
  const previousRoute = usePreviousRoute();

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = data.userProfileForm;

  const statusPerfil = watch("statusPerfil");

  const user = data.userForm.watch("user");

  const { insertUpdateUserProfile } = data.queries;

  if (user === null || (isUpdatePage && data.selectedUserProfile === null)) return <Navigate to={previousRoute} />;

  return (
    <div className="container">
      {!insertUpdateUserProfile.isFetching ? (
        <>
          <nav className={styles.navContainer}>
            <LinkButton buttonStyle="backButton" to={previousRoute} />
            <h1>
              {user.value.idUsuario} | {user.value.nome}
            </h1>
          </nav>
          <span className="separator" />
          <div className={styles.formContainer}>
            <Controller
              name={`nomePerfil`}
              control={control}
              rules={{
                required: "Digite o nome do perfil",
              }}
              render={({ field }) => (
                <div className={styles.formContainer__nameField}>
                  <label htmlFor={field.name} className="label">
                    Nome Perfil
                  </label>
                  <Input {...field} placeholder="Digite o nome do perfil" error={errors.nomePerfil?.message} />
                </div>
              )}
            />
            {isUpdatePage && (
              <Controller
                name={`statusPerfil`}
                control={control}
                render={({ field }) => (
                  <div className={styles.formContainer__statusField}>
                    <label htmlFor={field.name} className="label">
                      Status
                    </label>
                    <Select
                      {...field}
                      value={statusPerfil}
                      options={data.statusOptions ?? []}
                      error={errors.statusPerfil?.message}
                      isClearable={false}
                      isSearchable={false}
                    />
                  </div>
                )}
              />
            )}
          </div>
          <Button
            className={styles.buttonSaveData}
            onClick={() => {
              handleSubmit((formData) => {
                insertUpdateUserProfile.fetch({
                  idUsuario: user.value.idUsuario,
                  nomePerfil: formData.nomePerfil,
                  statusPerfil: formData.statusPerfil.value,
                  idPerfil: data.selectedUserProfile ? data.selectedUserProfile.id : 0,
                });
              })();
            }}
          >
            Salvar Dados
          </Button>
        </>
      ) : (
        <div className="loadingContainer" style={{ height: "500px", justifyContent: "center" }}>
          <Circle size={100} />
          <p className="loadingMessage">Salvando Dados</p>
        </div>
      )}
    </div>
  );
}
