import React from "react";

import styles from "./ApplicationsFrame.module.css";
import CardApplication from "./components/CardApplication";
import { AppStoreLogo } from "phosphor-react";

export default function ApplicationsFrame() {
  return (
    <div className={styles.applicationsContainer}>
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Sig"
        fullName="Sistema Interno de Gestão de Vendas"
        link="https://app.sig.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Apollo"
        fullName="Sistema de Chamados"
        link="https://apollo.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Analytics"
        fullName="Sistema de Análise de Dados"
        link="https://app.analytics.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Hermes"
        fullName="Sistema de Logística"
        link="https://app.hermes.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Demeter"
        fullName="Sistema de Bags"
        link="https://app.demeter.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Chronos Sync"
        fullName="Sistema de Pontos e Departamento Pessoal"
        link="https://app.chronossync.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="Portal Fábrica"
        fullName="Sistema da Fábrica"
        link="https://app.fabrica.agrocp.agr.br/"
      />
      <CardApplication
        icon={
          <AppStoreLogo size={50} color="var(--github-theme)" weight="fill" />
        }
        name="RPA"
        fullName="Sistema de RPA"
        link="https://app.rpa.agrocp.agr.br/"
      />
    </div>
  );
}
