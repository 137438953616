import React, { ReactNode } from "react";
import { ControlledCollapseItem } from "./ControlledCollapseItem";
import { useControlledCollapse, type CollapseItem as CollapseItemType } from "./hooks/useControlledCollapse";

import styles from "./ControlledCollapse.module.css";

interface CollapseProps {
  title: ReactNode;
  items: CollapseItemType[];
  showControllers?: boolean;
  className?: string;
}

export function ControlledCollapse({ title, items, showControllers = true, className = "" }: CollapseProps) {
  const { isOpen, toggle, expandAll, collapseAll } = useControlledCollapse(items);

  return (
    <div className={styles.container + " " + className}>
      <div className={styles.header}>
        {showControllers && (
          <button onClick={collapseAll} className={styles.collapseButton}>
            Colapsar tudo
          </button>
        )}
        <h2 className={styles.header__title}>{title}</h2>
        {showControllers && (
          <button onClick={() => expandAll(items)} className={styles.collapseButton}>
            Expandir tudo
          </button>
        )}
      </div>
      <span className="separator" />
      <div className={styles.body}>
        {items.map((item) => (
          <ControlledCollapseItem key={item.id} item={item} isOpen={isOpen} onToggle={toggle} />
        ))}
      </div>
    </div>
  );
}
