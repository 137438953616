import React, { ReactNode, useMemo } from "react";
import { Button, Select } from "../../../../components/Form";
import { useSelect } from "../../../../hooks/form";
import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useDebounce, useLoading } from "../../../../hooks/async";
import { Circle } from "../../../../components/Loading";

import styles from "./ByFormula.module.css";
import { isValid } from "../../../../helpers/validations";
import { ControlledCollapse } from "../../../../components/ControlledCollapse/ControlledCollapse";

interface IFormula {
  id: number;
  descricao: string;
}

interface ITable {
  id: number;
  descricao: string;
}

export interface IReportData {
  codigoEmpresa: number;
  nomeEmpresa: string;
  nomeTabela: string;
  dataBaseTabela: string;
  nomeVigencia: string;
  statusVigencia: "A" | "I";
  tipoComissao: "M" | "D";
  faixa: {
    letra: string;
    cor: string;
    dataManutencao: string;
    percComissao: number;
    vendedor: {
      idVendedor: number;
      nomeVendedor: string;
      percComissao: number;
      supervisor: {
        idSupervisor: number;
        nomeSupervisor: string;
        percComissao: number;
      }[];
    }[];
  }[];
}

export function CommissionsReportsByFormula() {
  const Modal = useModal();
  const customFetch = useCustomFetch();

  const formula = useSelect({ type: "single", required: true });
  const table = useSelect({ type: "single", required: false });

  const [reportData, setReportData] = React.useState<IReportData[]>([]);

  const formattedReportData = useMemo<ReactNode>(() => {
    if (reportData.length) {
      return (
        <ControlledCollapse
          className={styles.validityCollapse__container}
          title={<p className={styles.title}>TABELAS</p>}
          items={reportData.map((tabela, tabelaIndex) => ({
            id: tabelaIndex.toString(),
            title: (
              <div className={styles.dataContainer__tableInfo}>
                <p className={styles.dataContainer__tableInfo__table}>{tabela.nomeTabela}</p>
                <p className={styles.dataContainer__tableInfo__validity}>{tabela.nomeVigencia}</p>
              </div>
            ),
            classNameContainer: styles.validityCollapse__container,
            content: (
              <>
                <span className={styles.separator} style={{ marginTop: 0 }} />
                <ControlledCollapse
                  className={styles.validityCollapse__container}
                  title={<p className={styles.title}>FAIXAS</p>}
                  items={tabela.faixa.map((faixa, faixaIndex) => ({
                    id: faixaIndex.toString(),
                    title: (
                      <div className={styles.dataContainer__rangeInfo}>
                        <span className={styles.dataContainer__rangeInfo__ball} style={{ backgroundColor: faixa.cor }}>
                          {faixa.letra}
                        </span>
                        <p className={styles.dataContainer__rangeInfo__percent}>Percentual: {faixa.percComissao}%</p>
                      </div>
                    ),
                    classNameContainer: styles.validityCollapse__container,
                    content: (
                      <>
                        <span className={styles.separator} style={{ marginTop: 0 }} />
                        <ControlledCollapse
                          className={styles.validityCollapse__container}
                          title={<p className={styles.title}>VENDEDORES</p>}
                          items={faixa.vendedor.map((vendedor) => ({
                            id: vendedor.idVendedor.toString(),
                            title: (
                              <div className={styles.dataContainer__sellerInfo}>
                                <p className={styles.dataContainer__sellerInfo__seller}>{vendedor.nomeVendedor}</p>
                                <p className={styles.dataContainer__sellerInfo__percent}>
                                  Percentual: {vendedor.percComissao}%
                                </p>
                              </div>
                            ),
                            classNameContainer: styles.validityCollapse__container,
                            content: (
                              <div>
                                <span className={styles.separator} style={{ marginTop: 0 }} />
                                <div className={styles.supervisorContainer}>
                                  <h2 className={styles.title}>Supervisores</h2>
                                  {vendedor.supervisor.map((supervisor) => (
                                    <div className={styles.dataContainer__supervisorInfo}>
                                      <p className={styles.dataContainer__supervisorInfo__supervisor}>
                                        {supervisor.nomeSupervisor}
                                      </p>
                                      <p className={styles.dataContainer__supervisorInfo__percent}>
                                        Percentual: {supervisor.percComissao}%
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ),
                          }))}
                        />
                      </>
                    ),
                  }))}
                />
              </>
            ),
          }))}
        />
      );
    }
    return null;
  }, [reportData]);

  const [formulaOptions, setFormulaOptions] = React.useState<ISelectOption<IFormula>[]>([]);
  const [tableOptions, setTableOptions] = React.useState<ISelectOption<ITable>[]>([]);

  const searchingFormulaOptions = useLoading();
  const searchingTableOptions = useLoading();
  const searchingReportData = useLoading();

  const searchFormulaOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingFormulaOptions.setLoading(true);
        const json = await customFetch("/commissions/searchFormulas", {
          body: { descricao: description },
        });
        if (json.status === 200) {
          const options = json.object.map((item: IFormula) => ({
            value: item,
            label: `${item.id} | ${item.descricao}`,
          }));
          setFormulaOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setFormulaOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulaOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingFormulaOptions]
  );

  const searchFormulaOptionsDebounced = useDebounce(searchFormulaOptions);

  const searchTableOptions = React.useCallback(
    async (description: string = "") => {
      try {
        searchingTableOptions.setLoading(true);
        const json = await customFetch("/commissions/searchTables", {
          body: { descricao: description },
        });
        if (json.status === 200) {
          const options = json.object.map((item: ITable) => ({
            value: item,
            label: `${item.id} | ${item.descricao}`,
          }));
          setTableOptions(options);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        } else {
          setTableOptions([]);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingTableOptions.setLoading(false);
      }
    },
    [Modal, customFetch, searchingTableOptions]
  );

  const searchTableOptionsDebounced = useDebounce(searchTableOptions);

  const searchSellersByFormula = React.useCallback(async () => {
    if (!isValid(formula, table)) return;
    try {
      searchingReportData.setLoading(true);
      const json = (await customFetch("/commissions/searchSellersByFormula", {
        body: { idformulado: formula.value?.value.id, idTabela: table.value?.value.id || 0 },
      })) as DefaultFetchResponse<IReportData[]>;
      if (json.status === 200) {
        setReportData(json.object);
      } else if (json.status === 500) {
        Modal.error(json.message, json.object);
      } else {
        Modal.alert("Nenhum dado foi encontrado");
        setReportData([]);
      }
    } catch (error) {
      Modal.error(error);
    } finally {
      searchingReportData.setLoading(false);
    }
  }, [Modal, customFetch, formula, searchingReportData, table]);

  React.useEffect(() => {
    searchFormulaOptions();
    searchTableOptions();
  }, []); // eslint-disable-line

  return (
    <div className="container">
      <div className={styles.fieldsContainer}>
        <div>
          <label htmlFor="formula" className="label">
            Fórmula
          </label>
          <Select
            id="formula"
            placeholder="Selecione a fórmula"
            value={formula.value}
            onChange={formula.onChange}
            onInputChange={searchFormulaOptionsDebounced}
            options={formulaOptions}
            isLoading={searchingFormulaOptions.isLoading}
          />
        </div>
        <div>
          <label htmlFor="table" className="label">
            Tabela
          </label>
          <Select
            id="table"
            placeholder="Selecione uma tabela"
            value={table.value}
            onChange={table.onChange}
            onInputChange={searchTableOptionsDebounced}
            options={tableOptions}
            isLoading={searchingTableOptions.isLoading}
          />
        </div>
        <div className={styles.fieldsContainer__buttonContainer}>
          <Button className={styles.fieldsContainer__buttonContainer__button} onClick={searchSellersByFormula}>
            Buscar Dados
          </Button>
        </div>
      </div>
      {formattedReportData && !searchingReportData.isLoading ? (
        <div style={{ marginTop: "20px" }}>{formattedReportData}</div>
      ) : searchingReportData.isLoading ? (
        <div className={`loadingContainer ${styles.loadingContainer}`}>
          <Circle size={100} />
          <p className="loadingMessage">
            Buscando Dados
            <br />
            (Este processo pode levar algum tempo)
          </p>
        </div>
      ) : null}
    </div>
  );
}
