import React from "react";

import { Input, SeparatorList } from "../../../../../components/Form";
import { UseTabletSettings } from "../../hooks/useTabletSettings";

import styles from "./Geral.module.css";

import { Controller } from "react-hook-form";
import { Circle } from "../../../../../components/Loading";
import { selectOptionsDifference } from "../../../../../helpers/List";

interface Props {
  tabletSettings: UseTabletSettings;
}

export default function Geral({ tabletSettings }: Props) {
  const {
    changes,
    forms: { geralForm },
    queries: { sellersQuery, enterprisesQuery, saveUserGeneralTabletSettings, userTabletSettingsQuery },
  } = tabletSettings;

  const {
    control,
    formState: { errors },
  } = geralForm;

  return (
    <div className={styles.formContainer}>
      {!saveUserGeneralTabletSettings.isFetching ? (
        <>
          <div>
            <Controller
              name="host"
              control={control}
              rules={{
                required: "Preencha um valor",
              }}
              render={({ field }) => (
                <>
                  <label htmlFor="host" className="label">
                    Host
                  </label>
                  <Input
                    {...field}
                    id="host"
                    onChange={(e) => {
                      field.onChange(e);
                      changes.set();
                    }}
                    placeholder="000.000.000.000"
                    autoComplete="off"
                    error={errors.host?.message}
                    disabled={userTabletSettingsQuery.isFetching}
                  />
                </>
              )}
            />
          </div>
          <div>
            <Controller
              name="port"
              control={control}
              rules={{
                required: "Preencha um valor",
              }}
              render={({ field }) => (
                <>
                  <label htmlFor="port" className="label">
                    Porta
                  </label>
                  <Input
                    {...field}
                    id="port"
                    onChange={(e) => {
                      field.onChange(e);
                      changes.set();
                    }}
                    placeholder="8080"
                    error={errors.port?.message}
                    disabled={userTabletSettingsQuery.isFetching}
                  />
                </>
              )}
            />
          </div>
          <div className={styles.formContainer__sellers}>
            <Controller
              name="sellers"
              control={control}
              rules={{
                required: "Selecione ao menos um vendedor",
              }}
              render={({ field }) => {
                return (
                  <>
                    <label htmlFor="sellers" className="label">
                      Vendedores
                    </label>
                    <SeparatorList
                      {...field}
                      id="sellers"
                      value={field.value as any}
                      options={selectOptionsDifference(sellersQuery.data ?? [], field.value) as any}
                      placeholder="Selecione os vendedores"
                      onChange={(e: any) => {
                        field.onChange(e);
                        changes.set();
                      }}
                      onBlur={undefined}
                      onInputChange={undefined}
                      error={errors.sellers?.message}
                      isLoading={sellersQuery.isPending}
                      isDisabled={userTabletSettingsQuery.isFetching}
                    />
                  </>
                );
              }}
            />
          </div>
          <div className={styles.formContainer__enterprises}>
            <Controller
              name="enterprises"
              control={control}
              rules={{
                required: "Selecione ao menos uma empresa",
              }}
              render={({ field }) => {
                return (
                  <>
                    <label htmlFor="enterprises" className="label">
                      Empresas
                    </label>
                    <SeparatorList
                      {...field}
                      id="enterprises"
                      value={field.value as any}
                      options={selectOptionsDifference(enterprisesQuery.data ?? [], field.value) as any}
                      placeholder="Selecione as empresas"
                      onChange={(e: any) => {
                        field.onChange(e);
                        changes.set();
                      }}
                      onBlur={undefined}
                      onInputChange={undefined}
                      error={errors.enterprises?.message}
                      isLoading={enterprisesQuery.isPending}
                      isDisabled={userTabletSettingsQuery.isFetching}
                    />
                  </>
                );
              }}
            />
          </div>
        </>
      ) : (
        <div className="loadingContainer" style={{ height: "500px", gridColumn: "1/-1", justifyContent: "center" }}>
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
