import React, { useCallback, useState } from "react";

import { useForm } from "react-hook-form";
import { LoginApi } from "../../../../api/constants";
import useFetch from "../../../../api/hooks/useFetch";
import { useModal } from "../../../../hooks/contexts";
import { ITableData } from "../../../../components/Data/XTable";
import { useMemo } from "react";
import { Button } from "../../../../components/Form";
import { useNavigate } from "react-router-dom";
import { Pencil } from "phosphor-react";
import { usePreviousRoute } from "../../../../hooks/navigation";

export type User = LoginApi.Usuario.BuscarUsuarios.Usuario;
export type UserProfile = LoginApi.PerfilUsuario.BuscarPerfisUsuario.Perfil;

type UserForm = {
  user: ISelectOption<User> | null;
};

type UserProfileForm = {
  nomePerfil: string;
  statusPerfil: ISelectOption<"A" | "I">;
};

export default function useUserProfiles() {
  const Modal = useModal();
  const navigate = useNavigate();

  const previousRoute = usePreviousRoute();

  const userForm = useForm<UserForm>({
    mode: "onChange",
    defaultValues: {
      user: null,
    },
  });

  const userProfileForm = useForm<UserProfileForm>({
    mode: "onChange",
    defaultValues: {
      nomePerfil: "",
      statusPerfil: {
        value: "A",
        label: "Ativo",
      },
    },
  });

  const statusOptions = [
    {
      value: "A",
      label: "Ativo",
    },
    {
      value: "I",
      label: "Inativo",
    },
  ];

  const [selectedUserProfile, setSelectedUserProfile] = useState<UserProfile | null>(null);

  const searchUsers = useFetch(LoginApi.Usuario.BuscarUsuarios.ApiInfo, {
    select(data) {
      return data.object.map((user) => ({
        label: `${user.idUsuario} | ${user.nome}`,
        value: user,
      }));
    },
  });

  const searchUserProfiles = useFetch(LoginApi.PerfilUsuario.BuscarPerfisUsuario.ApiInfo, {
    select(data) {
      return data.object;
    },
  });

  const insertUpdateUserProfile = useFetch(LoginApi.PerfilUsuario.InserirAtualizarPerfilUsuario.ApiInfo, {
    async onSuccess(response) {
      userProfileForm.reset();
      userForm.handleSubmit((data) => searchUserProfiles.fetch({ idUsuario: data.user!.value.idUsuario }))();
      await Modal.success(response.message);
      navigate(previousRoute);
    },
    onError(response) {
      Modal.error(response.message);
    },
  });

  const populateUserProfileData = useCallback(
    (userProfile: UserProfile) => {
      userProfileForm.setValue("nomePerfil", userProfile.nome);
      userProfileForm.setValue("statusPerfil", {
        value: userProfile.status,
        label: userProfile.status === "A" ? "Ativo" : "Inativo",
      });
    },
    [userProfileForm]
  );

  const userProfilesTableData = useMemo<ITableData>(() => {
    const data =
      searchUserProfiles.data?.map((item) => ({
        ...item,
        edit: (
          <>
            <Button
              type="button"
              variant="edit"
              style={{
                margin: "auto",
                padding: "0.5rem 0.75rem",
                minWidth: "100%",
                width: "3.125rem",
              }}
              onClick={() => {
                setSelectedUserProfile(item);

                populateUserProfileData(item);

                navigate("atualizar");
              }}
              data-option-button
            >
              <Pencil weight="fill" />
            </Button>
          </>
        ),
      })) ?? [];

    return {
      columns: [
        {
          title: "ID",
          objectName: "id",
          isSorted: false,
          style: { width: "10px" },
        },
        {
          title: "Nome Perfil",
          objectName: "nome",
          isSorted: false,
        },
        {
          title: "Status",
          objectName: "status",
          isSorted: false,
          formatting: (value) => (value === "A" ? "Ativo" : "Inativo"),
          style: { width: "10px" },
        },
        {
          title: "",
          objectName: "edit",
          isSorted: false,
          style: { width: "10px" },
        },
      ],
      data: data,
    };
  }, [navigate, populateUserProfileData, searchUserProfiles.data]);

  return {
    userForm,
    userProfileForm,
    statusOptions,
    userProfilesTableData,
    selectedUserProfile,
    setSelectedUserProfile,
    queries: {
      searchUsers,
      searchUserProfiles,
      insertUpdateUserProfile,
      hasUserProfiles: searchUserProfiles.data !== null && searchUserProfiles.data.length > 0,
    },
  };
}

export type UseUserProfiles = ReturnType<typeof useUserProfiles>;
