import { API } from "../../hooks/useFetch";

/**
 * @description Contém todas as definições de endpoints para a API WebServiceLogin
 */
namespace LoginApi {}

/**
 * @description Contém todas as definições de endpoints para o grupo PerfilUsuario
 */
namespace LoginApi.PerfilUsuario {
  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os perfis de um usuário.
   *
   * @endpoint_api `/LoginApi/PerfilUsuario/buscarPerfisUsuario`
   *
   * @enpoint `/admin/searchUserProfiles`
   *
   * @method GET
   */
  export namespace BuscarPerfisUsuario {
    export type Perfil = { id: number; nome: string; status: "A" | "I" };

    export type ApiResponse = DefaultFetchResponse<Perfil[]>;

    export type ApiParams = { idUsuario: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/admin/searchUserProfiles",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que insere ou atualiza um perfil de usuário.
   *
   * @endpoint_api `/LoginApi/PerfilUsuario/inserirAtualizarPerfilUsuario`
   *
   * @enpoint `/admin/insertAndUpdateUserProfile`
   *
   * @method POST
   */
  export namespace InserirAtualizarPerfilUsuario {
    export type ApiResponse = DefaultFetchResponse<null>;

    export type ApiParams = {
      idUsuario: number;
      idPerfil?: number;
      nomePerfil: string;
      statusPerfil: "A" | "I";
    };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/admin/insertAndUpdateUserProfile",
      method: API.HttpMethod.POST,
    };
  }
}

/**
 * @description Contém todas as definições de endpoints para o grupo Usuario
 */
namespace LoginApi.Usuario {
  /**
     * @description Namespace que agrupa os tipos e definições para o endpoint que busca os usuários.
     *
     * @endpoint_api `/LoginApi/Usuario/buscarUsuarios`
     *
     * @enpoint `/admin/searchUsers`
     *
     * @method POST
     */
  export namespace BuscarUsuarios {
    export type Usuario = { idUsuario: number; nome: string; email: string };

    export type ApiResponse = DefaultFetchResponse<Usuario[]>;

    export type ApiParams = { idUsuario?: number, pesquisa: string };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/admin/searchUsers",
      method: API.HttpMethod.POST,
    };
  }
}

export default LoginApi;
