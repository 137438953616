import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

interface Params {
  data: {
    [key: string | number]: string | number;
  }[];
  fileName: string;
}

const excelExport = ({ data, fileName }: Params) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`);
};

export default excelExport;
