import React, { RefObject } from "react";
import StepsController from "./components/StepsController/StepsController";
import useStepsController from "./components/hooks/useStepsController";
import Geral from "./components/Geral/Geral";
import FatAdubo from "./components/FatAdubo/FatAdubo";
import FatSemDef from "./components/FatSemDef/FatSemDef";
import FatFoliar from "./components/FatFoliar/FatFoliar";
import { Button, Select } from "../../../components/Form";
import { Controller } from "react-hook-form";

import styles from "./TabletSettings.module.css";
import useTabletSettings from "./hooks/useTabletSettings";
import { useModal } from "../../../hooks/contexts";
import { PedidosApi } from "../../../api/constants";
import { Circle } from "../../../components/Loading";

interface ButtonOpenModalReplicateSettingsProps {
  user: ISelectOption<PedidosApi.BuscarUsuarios.Usuario> | null;
  profile: ISelectOption<PedidosApi.BuscarPerfisUsuario.Perfil> | null;
  isSearchingSettings: boolean;
  userTabletSettings: PedidosApi.BuscarConfiguracoesTabletUsuario.Configuracao | null;
  modalRef: RefObject<HTMLDialogElement>;
}

const ButtonOpenModalReplicateSettings = ({
  user,
  profile,
  isSearchingSettings,
  userTabletSettings,
  modalRef,
}: ButtonOpenModalReplicateSettingsProps) => {
  if (user !== null && profile !== null && !isSearchingSettings && !userTabletSettings) {
    return (
      <Button
        className={styles.buttonOpenModalReplicateSettings}
        variant="neutral"
        onClick={() => {
          modalRef.current?.show();
        }}
      >
        Copiar Configurações de Outro Usuário
      </Button>
    );
  }
  return null;
};

export function SettingsTabletSettings() {
  const Modal = useModal();

  const tabletSettings = useTabletSettings();

  const {
    user,
    profile,
    changes,
    forms: {
      userForm: {
        control,
        formState: { errors },
        resetField,
        handleSubmit,
      },
      originUserForm,
    },
    queries: {
      searchUsers,
      searchUserProfiles,
      searchOriginUserProfiles,
      userTabletSettingsQuery,
      replicateUserSettings,
    },
    modalReplicateSettingsRef,
  } = tabletSettings;

  const stepsController = useStepsController({ tabletSettings });

  const originUser = originUserForm.watch("originUser");

  return (
    <>
      <div className={`container`}>
        <div className={styles.userFormContainer}>
          <Controller
            name="user"
            control={control}
            render={({ field }) => (
              <div>
                <label htmlFor="user" className="label">
                  Usuário
                </label>
                <Select
                  {...field}
                  id="user"
                  placeholder="Selecione o usuário que deseja alterar"
                  onChange={async (value) => {
                    if (changes.isChangesDetected) {
                      const confirm = await Modal.confirm(
                        "Você tem alterações que ainda não foram salvas.<br />Deseja continuar?"
                      );
                      if (confirm) {
                        changes.reset();
                        field.onChange(value);

                        resetField("profile");

                        if (value !== null) {
                          searchUserProfiles.fetch({ idUsuario: value.value.idUsuario });
                        } else {
                          searchUserProfiles.clearData();
                        }
                      }
                    } else {
                      field.onChange(value);

                      resetField("profile");

                      if (value !== null) {
                        searchUserProfiles.fetch({ idUsuario: value.value.idUsuario });
                      } else {
                        searchUserProfiles.clearData();
                      }
                    }
                  }}
                  onInputChange={(value) => {
                    searchUsers.fetchDebounced({ descricao: value, quantidade: 100 });
                  }}
                  error={errors.user?.message}
                  options={searchUsers.data ?? []}
                  isLoading={searchUsers.isFetching}
                  isDisabled={userTabletSettingsQuery.isFetching || stepsController.isSavingConfigs}
                />
              </div>
            )}
          />

          {user && (
            <Controller
              name="profile"
              control={control}
              render={({ field }) => (
                <div>
                  <label htmlFor="profile" className="label">
                    Perfil de Usuário
                  </label>
                  <Select
                    {...field}
                    id="profile"
                    placeholder="Selecione o perfil de usuário que deseja alterar"
                    onChange={async (value) => {
                      if (changes.isChangesDetected) {
                        const confirm = await Modal.confirm(
                          "Você tem alterações que ainda não foram salvas.<br />Deseja continuar?"
                        );
                        if (confirm) {
                          changes.reset();
                          field.onChange(value);
                          stepsController.changeStep(0);
                        }
                      } else {
                        field.onChange(value);
                        stepsController.changeStep(0);
                      }
                    }}
                    noOptionsMessage={"O Usuário Não Possui Perfis Registrados"}
                    error={errors.profile?.message}
                    options={searchUserProfiles.data ?? []}
                    isLoading={searchUserProfiles.isFetching}
                    isDisabled={userTabletSettingsQuery.isFetching || stepsController.isSavingConfigs}
                  />
                </div>
              )}
            />
          )}
        </div>
        <ButtonOpenModalReplicateSettings
          user={user}
          profile={profile}
          isSearchingSettings={userTabletSettingsQuery.isFetching}
          userTabletSettings={userTabletSettingsQuery.data!}
          modalRef={modalReplicateSettingsRef}
        />
        <span className="separator" />
        {user && profile ? (
          <div className={`${styles.configsContainer}`}>
            <StepsController
              pages={[
                {
                  name: "GERAL",
                  content: <Geral tabletSettings={tabletSettings} />,
                },
                {
                  name: "FAT. ADUBO",
                  content: <FatAdubo tabletSettings={tabletSettings} />,
                },
                {
                  name: "FAT. SEM/DEF",
                  content: <FatSemDef tabletSettings={tabletSettings} />,
                },
                {
                  name: "FAT. FOLIAR",
                  content: <FatFoliar tabletSettings={tabletSettings} />,
                },
              ]}
              useStepsController={stepsController}
              disabled={stepsController.isSavingConfigs}
            />

            <Button
              className={styles.buttonSaveData}
              onClick={() => {
                stepsController.saveSettingsByStep();
              }}
              disabled={
                stepsController.isSavingConfigs ||
                tabletSettings.queries.userTabletSettingsQuery.isFetching ||
                !changes.isChangesDetected
              }
            >
              Salvar Dados
            </Button>
          </div>
        ) : (
          <p className="lineCardMessage">Selecione um usuário para alterar as configurações</p>
        )}
      </div>
      <dialog
        className={`modal`}
        ref={modalReplicateSettingsRef}
        onClose={() => {
          originUserForm.reset();
        }}
      >
        <div className={`modalContent ${styles.modal}`}>
          {!replicateUserSettings.isFetching ? (
            <>
              <div className={styles.modalCloseContainer}>
                <button
                  className={styles.modalCloseContainer__button}
                  onClick={() => {
                    modalReplicateSettingsRef.current?.close();
                  }}
                >
                  X
                </button>
              </div>
              <span className="separator" />
              <div className={styles.modalInputGroup}>
                <div>
                  <Controller
                    name="originUser"
                    control={originUserForm.control}
                    rules={{
                      required: "Selecione o usuário que deseja copiar",
                    }}
                    render={({ field }) => (
                      <div>
                        <label htmlFor="originUser" className="label">
                          Usuário Origem
                        </label>
                        <Select
                          {...field}
                          id="originUser"
                          placeholder="Selecione o usuário que deseja copiar"
                          onChange={async (value) => {
                            field.onChange(value);
                            originUserForm.resetField("originProfile");

                            if (value !== null) {
                              searchOriginUserProfiles.fetch({ idUsuario: value.value.idUsuario });
                            } else {
                              searchOriginUserProfiles.clearData();
                            }
                          }}
                          onInputChange={(value) => {
                            searchUsers.fetchDebounced({ descricao: value, quantidade: 100 });
                          }}
                          error={originUserForm.formState.errors.originUser?.message}
                          options={searchUsers.data ?? []}
                          isLoading={searchUsers.isFetching}
                        />
                      </div>
                    )}
                  />
                </div>
                {originUser && (
                  <div>
                    <Controller
                      name="originProfile"
                      control={originUserForm.control}
                      render={({ field }) => (
                        <div>
                          <label htmlFor="originProfile" className="label">
                            Perfil de Usuário Origem
                          </label>
                          <Select
                            {...field}
                            id="originProfile"
                            placeholder="Selecione o perfil de usuário que deseja copiar"
                            onChange={field.onChange}
                            noOptionsMessage={"O Usuário Não Possui Perfis Registrados"}
                            error={originUserForm.formState.errors.originProfile?.message}
                            options={searchOriginUserProfiles.data ?? []}
                            isLoading={searchOriginUserProfiles.isFetching}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </div>
              <div className={styles.modalInputGroup} style={{ marginTop: "10px" }}>
                <div>
                  <Controller
                    name="user"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <label htmlFor="user" className="label">
                          Usuário Destino
                        </label>
                        <Select {...field} id="user" options={[]} isDisabled />
                      </div>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="profile"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <label htmlFor="profile" className="label">
                          Perfil de Usuário Destino
                        </label>
                        <Select {...field} id="profile" options={[]} isDisabled />
                      </div>
                    )}
                  />
                </div>
              </div>
              <span className="separator" />
              <div className={styles.modalButtonContainer}>
                <Button
                  onClick={() => {
                    originUserForm.handleSubmit((originUserData) => {
                      handleSubmit((userData) => {
                        replicateUserSettings.fetch({
                          idUsuarioOrigem: originUserData.originUser?.value.idUsuario!,
                          idPerfilOrigem: originUserData.originProfile?.value.id!,
                          idUsuarioDestino: userData.user?.value.idUsuario!,
                          idPerfilDestino: userData.profile?.value.id!,
                        });
                      })();
                    })();
                  }}
                >
                  Confirmar Replicação
                </Button>
              </div>
            </>
          ) : (
            <div className="loadingContainer">
              <Circle size={100} />
              <p className="loadingMessage">Replicando configurações</p>
            </div>
          )}
        </div>
      </dialog>
    </>
  );
}
