import React from "react";

import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../hooks/contexts";
import { useDebounce } from "../../../../hooks/async";

import { Table, TBody, Td, Th, THead, Tr } from "../../../../components/Data/Table";
import { Input } from "../../../../components/Form";
import { Paginate } from "../../../../components/Paginate/Paginate";
import { formatDate } from "../../../../helpers/format";
import { Button, LinkButton, Select } from "../../../../components/Form";

import { Circle } from "../../../../components/Loading";

import { Pencil } from "phosphor-react";

import styles from "./FormulaList.module.css";

export function FormulaList({
  pagination,
  searchFormulas,
  searchTotalFormulaRecordsAndFormulas,
  searchedFormula,
  searchedGroup,
  searchedStatus,
  groupOptions,
  searchingGroupOptions,
  formulaList,
  setSelectedFormula,
  searchingFormulas,
  generateFormulasReport,
  generatingFormulasReport,
}) {
  const Modal = useModal();
  const navigate = useNavigate();

  const statusOptions = [
    {
      value: "both",
      label: "ATIVOS E INATIVOS",
    },
    {
      value: true,
      label: "ATIVOS",
    },
    {
      value: false,
      label: "INATIVOS",
    },
  ];

  const handleClickEditButton = React.useCallback(
    (formulaData) => {
      setSelectedFormula(formulaData);
      navigate("atualizar");
    },
    [navigate, setSelectedFormula]
  );

  const handlePageChange = React.useCallback(
    async (formula, groupId, status, page) => {
      try {
        searchingFormulas.setLoading(true);
        const json = await searchFormulas(formula, groupId, status, page);
        if (json.status === 200) {
          formulaList.setValue(json.object);
        } else if (json.status === 500) {
          Modal.error(json.message, json.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        searchingFormulas.setLoading(false);
      }
    },
    [Modal, formulaList, searchFormulas, searchingFormulas]
  );

  const { current: searchformulaList } = React.useRef(
    useDebounce((formula, groupId, status) => {
      searchTotalFormulaRecordsAndFormulas(formula, groupId, status);
    })
  );

  React.useEffect(() => {
    if (!formulaList.value.length) searchTotalFormulaRecordsAndFormulas();
    if (!searchedStatus.value) searchedStatus.setValue(statusOptions[0]);
  }, []); //eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      {!generatingFormulasReport ? (
        <>
          <div className={`${styles.buttonContainer}`}>
            <Button
              onClick={() => {
                generateFormulasReport();
              }}
            >
              Gerar Relatório
            </Button>
            <LinkButton to="novo">Nova Fórmula</LinkButton>
          </div>
          <div className={styles.filterContainer}>
            <div className={`${styles.filterByFormulaContainer}`}>
              <label className="label">Filtrar Fórmulas</label>
              <Input
                placeholder="Busque por uma fórmula"
                value={searchedFormula.value}
                onChange={({ target }) => {
                  searchedFormula.setValue(target.value);
                  searchformulaList(target.value, searchedGroup.value?.value?.idGrupo, searchedStatus.value.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="grupo" className="label">
                Filtrar Grupos
              </label>
              <Select
                id="grupo"
                placeholder="Selecione um grupo"
                options={groupOptions}
                value={searchedGroup.value}
                onChange={(value) => {
                  searchedGroup.setValue(value);
                  searchTotalFormulaRecordsAndFormulas(
                    searchedFormula.value,
                    value?.value?.idGrupo,
                    searchedStatus.value.value
                  );
                }}
                isLoading={searchingGroupOptions.isLoading}
              />
            </div>
            <div>
              <label htmlFor="status" className="label">
                Filtrar Status
              </label>
              <Select
                id="status"
                placeholder="Selecione o status"
                options={statusOptions}
                value={searchedStatus.value}
                onChange={(value) => {
                  searchTotalFormulaRecordsAndFormulas(
                    searchedFormula.value,
                    searchedGroup.value?.value?.idGrupo,
                    value.value
                  );
                  searchedStatus.setValue(value);
                }}
                isClearable={false}
                isSearchable={false}
              />
            </div>
          </div>

          <div className={`${styles.formulaListContainer}`}>
            {formulaList.value.length && !searchingFormulas.isLoading ? (
              <Table title="Fórmulas Cadastradas" className={styles.tableFormula}>
                <THead>
                  <Tr>
                    <Th>ID</Th>
                    <Th>Descrição</Th>
                    <Th>ID / Grupo</Th>
                    <Th>Situação</Th>
                    <Th>Última modificação</Th>
                    <Th></Th>
                  </Tr>
                </THead>
                <TBody>
                  {formulaList.value.map((formula) => {
                    return (
                      <Tr key={formula.formulado.idFormulado}>
                        <Td data-heading="ID">{formula.formulado.idFormulado}</Td>
                        <Td data-heading="Descrição">{formula.formulado.descricaoFormulado}</Td>
                        <Td data-heading="ID / Grupo">{`${formula.grupo.idGrupo} - ${formula.grupo.descricaoGrupo}`}</Td>
                        <Td data-heading="Situação">
                          {formula.formulado.situacaoFormulado === "A" ? "Ativo" : "Inativo"}
                        </Td>
                        <Td data-heading="Última modificação">
                          {formatDate(formula.formulado.dataFormulado, "dd/MM/yyyy às hh:mm:ss")}
                        </Td>
                        <Td data-heading="Editar" data-option>
                          <Button
                            className={styles.editFormulaButton}
                            onClick={() => {
                              const selectedFormula = {
                                formulado: {
                                  ...formula.formulado,
                                  situacaoFormulado: !!(formula.formulado.situacaoFormulado === "A"),
                                },
                                grupo: {
                                  ...formula.grupo,
                                },
                              };
                              handleClickEditButton(selectedFormula);
                            }}
                            data-option-button
                          >
                            <Pencil weight="fill" />
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
            ) : searchingFormulas.isLoading ? (
              <div className={styles.searchingFormulasLoadingContainer}>
                <Circle size={100} />
              </div>
            ) : (
              <p className={styles.noFormulasMessage}>Nenhuma fórmula encontrada</p>
            )}
          </div>
          <div>
            <Paginate
              classNameContainer={styles.paginationContainer}
              totalRecords={pagination.totalRecords}
              maxItems={pagination.maxItems}
              currentPage={pagination.currentPage}
              setCurrentPage={pagination.setCurrentPage}
              onPageChange={(page) =>
                handlePageChange(
                  searchedFormula.value,
                  searchedGroup.value?.value?.idGrupo,
                  searchedStatus.value.value,
                  page
                )
              }
            />
          </div>
        </>
      ) : (
        <div className={styles.searchingFormulasLoadingContainer}>
          <Circle size={100} />
        </div>
      )}
    </div>
  );
}
