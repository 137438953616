import { useState, useCallback, ReactNode } from "react";

export type CollapseItem = {
  id: string;
  title: ReactNode;
  classNameContainer?: string;
  content?: ReactNode;
  items?: CollapseItem[];
};

export function useControlledCollapse(items: CollapseItem[]) {
  const [openItems, setOpenItems] = useState<Set<string>>(new Set());

  const isOpen = useCallback((id: string) => openItems.has(id), [openItems]);

  const toggle = useCallback((id: string) => {
    setOpenItems((current) => {
      const next = new Set(current);
      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }
      return next;
    });
  }, []);

  const expandAll = useCallback((items: CollapseItem[]) => {
    const getAllIds = (items: CollapseItem[]): string[] => {
      return items.reduce<string[]>((acc, item) => {
        acc.push(item.id);
        if (item.items) {
          acc.push(...getAllIds(item.items));
        }
        return acc;
      }, []);
    };

    setOpenItems(new Set(getAllIds(items)));
  }, []);

  const collapseAll = useCallback(() => {
    setOpenItems(new Set());
  }, []);

  return {
    isOpen,
    toggle,
    expandAll,
    collapseAll,
  };
}
