import { API } from "../../hooks/useFetch";

/**
 * @description Contém todas as definições de endpoints para a API PedidosAPI
 */
export namespace PedidosApi {
  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os usuários ativos no sistema.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarUsuarios`
   *
   * @enpoint `/pedidosAPI/searchUsers`
   *
   * @method GET
   */
  export namespace BuscarUsuarios {
    export type Usuario = { idUsuario: number; nome: string };

    export type ApiResponse = DefaultFetchResponse<Usuario[]>;

    export type ApiParams = { descricao: string; quantidade?: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchUsers",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os perfis de usuário ativos no sistema.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarPerfisUsuario`
   *
   * @enpoint `/pedidosAPI/searchUserProfiles`
   *
   * @method GET
   */
  export namespace BuscarPerfisUsuario {
    export type Perfil = { id: number; nome: string };

    export type ApiResponse = DefaultFetchResponse<Perfil[]>;

    export type ApiParams = { idUsuario: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchUserProfiles",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os vendedores ativos e inativos no tablet de um usuário.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarVendedoresAtivosInativosUsuario`
   *
   * @enpoint `/pedidosAPI/searchActiveAndInactiveUserSellers`
   *
   * @method GET
   */
  export namespace BuscarVendedoresAtivosInativosTabletUsuario {
    export type Vendedor = { idVendedor: number; nome: string; situacao: "A" | "I" };

    export type ApiResponse = DefaultFetchResponse<Vendedor[]>;

    export type ApiParams = { idUsuario: number; idPerfil: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchActiveAndInactiveUserSellers",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca as empresas ativas e inativas no tablet de um usuário.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarEmpresasAtivasInativasUsuario`
   *
   * @enpoint `/pedidosAPI/searchActiveAndInactiveUserEnterprises`
   *
   * @method GET
   */
  export namespace BuscarEmpresasAtivasInativasTabletUsuario {
    export type Empresa = { idEmpresa: number; nomeEmpresa: string; nomeFantasia: string; situacao: "A" | "I" };

    export type ApiResponse = DefaultFetchResponse<Empresa[]>;

    export type ApiParams = { idUsuario: number; idPerfil: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchActiveAndInactiveUserEnterprises",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os supervisores cadastrados.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarSupervisores`
   *
   * @enpoint `/pedidosAPI/searchSupervisors`
   *
   * @method GET
   */
  export namespace BuscarSupervisores {
    export type Supervisor = {
      idSupervisor: number;
      nome: string;
      situacao: "A" | "I";
    };

    export type ApiResponse = DefaultFetchResponse<Supervisor[]>;

    export type ApiParams = void;

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchSupervisors",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca as operações cadastradas.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarOperacoes`
   *
   * @enpoint `/pedidosAPI/searchOperations`
   *
   * @method GET
   */
  export namespace BuscarOperacoes {
    export type Operacao = {
      id: number;
      nome: string;
      situacao: "A" | "I";
    };

    export type ApiResponse = DefaultFetchResponse<Operacao[]>;

    export type ApiParams = void;

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchOperations",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca as condições de pagamento cadastradas.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarCondicoesPagamento`
   *
   * @enpoint `/pedidosAPI/searchPaymentConditions`
   *
   * @method GET
   */
  export namespace BuscarCondicoesPagamento {
    export type CondicaoPagamento = {
      idCondicaoPagamento: number;
      descricao: string;
      situacao: "A" | "I";
      prazo: null;
    };

    export type ApiResponse = DefaultFetchResponse<CondicaoPagamento[]>;

    export type ApiParams = void;

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchPaymentConditions",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca as tabelas de vigência cadastradas.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarTabelasVigencia`
   *
   * @enpoint `/pedidosAPI/searchValidityTables`
   *
   * @method GET
   */
  export namespace BuscarTabelasVigencia {
    export type TabelaVigencia = {
      idVigencia: number;
      nome: string;
    };

    export type ApiResponse = DefaultFetchResponse<TabelaVigencia[]>;

    export type ApiParams = void;

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchValidityTables",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca os depositos cadastrados.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarDepositos`
   *
   * @enpoint `/pedidosAPI/searchDeposits`
   *
   * @method GET
   */
  export namespace BuscarDepositos {
    export type Deposito = {
      idDeposito: number;
      descricao: string;
      status: "A" | "I";
    };

    export type ApiResponse = DefaultFetchResponse<Deposito[]>;

    export type ApiParams = void;

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchDeposits",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que busca as configurações definidas para o tablet de um usuario.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/buscarConfiguracoesUsuario`
   *
   * @enpoint `/pedidosAPI/searchUserTabletSettings`
   *
   * @method GET
   */
  export namespace BuscarConfiguracoesTabletUsuario {
    export type Configuracao = {
      idConfiguracaoTablet: number;
      idUsuario: number;
      ipHost: string;
      porta: string;
      configuracaoTabletVendedor: ConfiguracaoTabletVendedor[];
      configuracaoTabletEmpresa: ConfiguracaoTabletEmpresa[];
      configuracaoTabletAdubo: ConfiguracaoTabletAdubo | null;
      configuracaoTabletFoliar: ConfiguracaoTabletFoliar | null;
      configuracaoTabletSementeDefensivo: ConfiguracaoTabletSementeDefensivo | null;
      dataCriacao: string;
      idUsuarioCriacao: any;
      dataAlteracao: string;
      idUsuarioAteracao: any;
      status: string;
    };

    export type ConfiguracaoTabletVendedor = {
      idConfiguracaoTabletVendedor: number;
      idConfiguracaoTablet: number;
      configuracaoTablet: any;
      idVendedor: number;
      vendedor: Vendedor;
      dataCriacao: string;
      idUsuarioCriacao: any;
      dataAlteracao: string;
      idUsuarioAteracao: any;
      status: string;
    };

    export type ConfiguracaoTabletEmpresa = {
      idConfiguracaoTabletEmpresa: number;
      idConfiguracaoTablet: number;
      configuracaoTablet: any;
      idEmpresa: number;
      empresa: Empresa;
      dataCriacao: string;
      idUsuarioCriacao: any;
      dataAlteracao: string;
      idUsuarioAteracao: any;
      status: string;
    };

    export type ConfiguracaoTabletAdubo = {
      idConfiguracaoTabletAdubo: number;
      idConfiguracaoTablet: number;
      configuracaoTablet: any;
      idVendedorPadrao: number;
      vendedor: Vendedor;
      idSupervisorPadrao: number;
      supervisor: Supervisor;
      idCondicaoPadrao: number;
      condicaoPagamento: CondicaoPagamento;
      idOperacaoPadrao: number;
      operacao: Operacao;
      idTabelaPadrao: number;
      tabela: Tabela;
      idEmpresaPadrao: number;
      empresa: Empresa;
      idDepositoPadrao: number;
      deposito: Deposito;
      verificaClienteContrato: "S" | "N";
      statusConfigTabletAdubo: "S" | "N";
      dataCriacao: string;
      idUsuarioCriacao: any;
      dataAlteracao: string;
      idUsuarioAteracao: any;
      status: "A" | "I";
    };

    export type ConfiguracaoTabletSementeDefensivo = {
      idConfiguracaoTabletSemDef: number;
      idConfiguracaoTablet: number;
      configuracaoTablet: any;
      idVendedorPadrao: number;
      vendedor: Vendedor;
      idSupervisorPadrao: number;
      supervisor: Supervisor;
      idCondicaoPadrao: any;
      idOperacaoPadrao: number;
      operacao: Operacao;
      idTabelaPadrao: any;
      idEmpresaPadrao: number;
      empresa: Empresa;
      idDepositoPadrao: number;
      deposito: Deposito;
      verificaClienteContrato: any;
      statusConfigTabletSementeDefensivo: "S" | "N";
      dataCriacao: string;
      idUsuarioCriacao: any;
      dataAlteracao: string;
      idUsuarioAteracao: any;
      status: "A" | "I";
    };

    export type ConfiguracaoTabletFoliar = {
      idConfiguracaoTabletFoliar: number;
      idConfiguracaoTablet: number;
      configuracaoTablet: any;
      idVendedorPadrao: number;
      vendedor: Vendedor;
      idSupervisorPadrao: number;
      supervisor: Supervisor;
      idCondicaoPadrao: any;
      idOperacaoPadrao: number;
      operacao: Operacao;
      idTabelaPadrao: any;
      idEmpresaPadrao: number;
      empresa: Empresa;
      idDepositoPadrao: any;
      verificaClienteContrato: any;
      statusConfigTabletFoliar: any;
      dataCriacao: string;
      idUsuarioCriacao: any;
      dataAlteracao: string;
      idUsuarioAteracao: any;
      status: "A" | "I";
    };

    export type Vendedor = {
      idVendedor: number;
      nome: string;
      situacao: "A" | "I";
    };

    export type Supervisor = {
      idSupervisor: number;
      nome: string;
      situacao: string;
    };

    export type CondicaoPagamento = {
      idCondicaoPagamento: number;
      descricao: string;
      situacao: string;
      dataManutencao: string;
    };

    export type Operacao = {
      idOperacao: number;
      descricao: string;
      situacao: string;
      dataManutencao: string;
      solicitaDataVencimento: string;
      geraNotaFiscal: string;
      bonificacao: string;
      trocaOpercao: string;
      creditoRural: string;
      pagaPremiacao: string;
      operacaoSiagriCodigo: number;
      operacaoSiagriDescricao: any;
    };

    export type Tabela = {
      idVigencia: number;
      nome: string;
      idTabelaPreco: number;
      tabelaPreco: any;
      dataManutencao: string;
      dataCriacao: string;
      jurosMes: number;
      desconto: any;
      valorFrete: number;
      adicionarFrete: string;
      tipoJuros: string;
      diasCobrarJuros: number;
      ativo: string;
      campanha: any;
      vigenciaItens: any[];
    };

    export type Empresa = {
      idEmpresa: number;
      idCidade: number;
      cidade: any;
      nomeEmpresa: string;
      cnpj: string;
      inscricaoEstadual: string;
      inscricaoMunicipal: string;
      endereco: string;
      numero: string;
      bairro: string;
      cep: string;
      telefone: string;
      complemento: string;
      nomeFantasia: string;
      situacao: "A" | "I";
      razaoSocial: any;
      dataModificacao: string;
    };

    export type Deposito = {
      idDeposito: number;
      descricao: string;
      status: string;
    };

    export type ApiResponse = DefaultFetchResponse<Configuracao | null>;

    export type ApiParams = { idUsuario: number; idPerfil: number };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/searchUserTabletSettings",
      method: API.HttpMethod.GET,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que salva as configurações gerais para o tablet de um usuário.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/salvarConfiguracoesGeraisUsuario`
   *
   * @enpoint `/pedidosAPI/saveUserGeneralTabletSettings`
   *
   * @method POST
   */
  export namespace SalvarConfiguracoesGeraisTabletUsuario {
    export type ApiResponse = DefaultFetchResponse<null>;

    /**
     * @property {number} id - ID da configuração.
     */
    export type ApiParams = {
      id: number;
      idUsuario: number;
      idPerfil: number;
      ipHost: string;
      porta: string;
      vendedores: number[];
      empresas: number[];
    };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/saveUserGeneralTabletSettings",
      method: API.HttpMethod.POST,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que salva as configurações de adubo para o tablet de um usuário.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/salvarConfiguracoesAduboUsuario`
   *
   * @enpoint `/pedidosAPI/saveUserFertilizerTabletSettings`
   *
   * @method POST
   */
  export namespace SalvarConfiguracoesAduboTabletUsuario {
    export type ApiResponse = DefaultFetchResponse<null>;

    export type ApiParams = {
      id: number;
      idConfiguracaoTablet: number;
      idVendedorPadrao: number;
      idSupervisorPadrao: number;
      idCondicaoPagamentoPadrao: number | null;
      idOperacaoPadrao: number | null;
      idTabelaVigenciaPadrao: number | null;
      idEmpresaPadrao: number;
      idDepositoPadrao: number;
      verificaContratoCliente: "S" | "N";
    };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/saveUserFertilizerTabletSettings",
      method: API.HttpMethod.POST,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que salva as configurações de foliar para o tablet de um usuário.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/salvarConfiguracoesFoliarUsuario`
   *
   * @enpoint `/pedidosAPI/saveUserFoliarTabletSettings`
   *
   * @method POST
   */
  export namespace SalvarConfiguracoesFoliarTabletUsuario {
    export type ApiResponse = DefaultFetchResponse<null>;

    export type ApiParams = {
      id: number;
      idConfiguracaoTablet: number;
      idVendedorPadrao: number;
      idSupervisorPadrao: number;
      idOperacaoPadrao: number | null;
      idEmpresaPadrao: number;
    };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/saveUserFoliarTabletSettings",
      method: API.HttpMethod.POST,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que salva as configurações de semente defensivo para o tablet de um usuário.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/salvarConfiguracoesSementeDefensivoUsuario`
   *
   * @enpoint `/pedidosAPI/saveUserSemDefTabletSettings`
   *
   * @method POST
   */
  export namespace SalvarConfiguracoesSementeDefensivoTabletUsuario {
    export type ApiResponse = DefaultFetchResponse<null>;

    export type ApiParams = {
      id: number;
      idConfiguracaoTablet: number;
      idVendedorPadrao: number;
      idSupervisorPadrao: number;
      idOperacaoPadrao: number | null;
      idEmpresaPadrao: number;
      idDepositoPadrao: number;
      statusConfigSemDef: "S" | "N";
    };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/saveUserSemDefTabletSettings",
      method: API.HttpMethod.POST,
    };
  }

  /**
   * @description Namespace que agrupa os tipos e definições para o endpoint que replica as configurações de um usuário para outro.
   *
   * @endpoint_api `/pedidosAPI/api/configuracoesTablet/replicarConfiguracoesUsuario`
   *
   * @enpoint `/pedidosAPI/replicateUserSettings`
   *
   * @method POST
   */
  export namespace ReplicarConfiguracoesTabletUsuario {
    export type ApiResponse = DefaultFetchResponse<null>;

    export type ApiParams = {
      idUsuarioOrigem: number;
      idPerfilOrigem: number;
      idUsuarioDestino: number;
      idPerfilDestino: number;
    };

    /**
     * @description Objeto que contém a URL e o método HTTP usados para fazer a requisição. Usado em conjunto com o hook `useFetch`.
     */
    export const ApiInfo: API.Info<ApiResponse, ApiParams> = {
      url: "/pedidosAPI/replicateUserSettings",
      method: API.HttpMethod.POST,
    };
  }
}

export default PedidosApi;