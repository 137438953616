import React from "react";
import type { CollapseItem as CollapseItemType } from "./hooks/useControlledCollapse";
import { CaretRight } from "phosphor-react";

import styles from "./ControlledCollapseItem.module.css";

interface CollapseItemProps {
  item: CollapseItemType;
  isOpen: (id: string) => boolean;
  onToggle: (id: string) => void;
  level?: number;
}

export function ControlledCollapseItem({ item, isOpen, onToggle, level = 0 }: CollapseItemProps) {
  const hasContent = !!item.content;
  const isExpanded = isOpen(item.id);

  return (
    <div className={styles.container + " " + (item.classNameContainer ?? "")}>
      <div className={styles.collapseHeader}>
        <button
          className={`${styles.button} ${level > 0 ? styles.buttonIndented : ""}`}
          onClick={() => hasContent && onToggle(item.id)}
          style={{ "--level": level } as React.CSSProperties}
        >
          {item.title}
          {hasContent && (
            <CaretRight
              className={`${styles.icon} ${isExpanded ? styles.iconExpanded : ""}`}
              size={20}
              color={"var(--gray-8)"}
              weight="fill"
            />
          )}
        </button>
      </div>
      {isExpanded && hasContent && (
        <div className={styles.expanded}>{hasContent && <p className={styles.content}>{item.content}</p>}</div>
      )}
    </div>
  );
}
