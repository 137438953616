import styles from "./SeparatorList.module.css";

import { ArrowsLeftRight, CaretDown, CaretLeft, CaretRight } from "phosphor-react";
import React from "react";

export function SeparatorList({
  id,
  options = [],
  value: selectedOptions = "",
  error,
  onChange,
  onBlur,
  onInputChange,
  placeholder,
  isDisabled,
  isLoading,
}) {
  const [isOpened, setIsOpened] = React.useState(false);

  const refContainer = React.useRef();
  const refInput = React.useRef();
  const refListOfUnselected = React.useRef();
  const refListOfSelected = React.useRef();

  const [inputState, setInputState] = React.useState("");

  const isFilled = !!selectedOptions?.length;

  const toggleOpened = React.useCallback((e) => {
    e.stopPropagation();
    setIsOpened((value) => {
      if (value) {
        refInput.current.value = "";
        setInputState("");
      }
      return !value;
    });
  }, []);

  const handleFocus = React.useCallback(() => {
    if (!isOpened && !isDisabled) {
      setIsOpened(true);
    }
  }, [isOpened, isDisabled]);

  const selectOptions = React.useCallback(
    (arrayOptions) => {
      const arrayValues = arrayOptions.map((item) => JSON.parse(item.value));

      const finalArray = [...selectedOptions, ...arrayValues];

      onChange(finalArray);
    },
    [onChange, selectedOptions]
  );

  const deselectOptions = React.useCallback(
    (arrayOptions) => {
      const arrayValues = arrayOptions.map((item) => JSON.parse(item.value));

      const finalArray = selectedOptions.filter((option) => {
        return !arrayValues.some((item) => JSON.stringify(item) === JSON.stringify(option));
      });

      onChange(finalArray);
    },
    [onChange, selectedOptions]
  );

  const sendToRight = React.useCallback(() => {
    const inputsSelected = Array.from(refListOfUnselected.current.querySelectorAll("input:checked"));

    if (inputsSelected.length) {
      selectOptions(inputsSelected);

      inputsSelected.forEach((input) => {
        input.checked = false;
        input.parentElement.classList.remove("active");
      });
    }
  }, [selectOptions]);

  const sendToLeft = React.useCallback(() => {
    const inputsSelected = Array.from(refListOfSelected.current.querySelectorAll("input:checked"));

    if (inputsSelected.length) {
      deselectOptions(inputsSelected);

      inputsSelected.forEach((input) => {
        input.checked = false;
        input.parentElement.classList.remove("active");
      });
    }
  }, [deselectOptions]);

  const toggleOptions = React.useCallback(() => {
    const leftInputs = Array.from(refListOfUnselected.current.querySelectorAll('input[type="checkbox"]'));
    const rightInputs = Array.from(refListOfSelected.current.querySelectorAll('input[type="checkbox"]'));

    if (leftInputs.length || rightInputs.length) {
      if (leftInputs.length > rightInputs.length) {
        selectOptions(leftInputs);
      } else {
        deselectOptions(rightInputs);
      }

      leftInputs.forEach((input) => {
        input.checked = false;
        input.parentElement.classList.remove("active");
      });
      rightInputs.forEach((input) => {
        input.checked = false;
        input.parentElement.classList.remove("active");
      });
    }
  }, [deselectOptions, selectOptions]);

  const toggleActiveClass = React.useCallback((e) => {
    const label = e.target.parentElement;
    if (e.target.checked) {
      label.classList.add("active");
    } else {
      label.classList.remove("active");
    }
  }, []);

  React.useEffect(() => {
    const html = document.documentElement;
    const events = ["click", "touchstart"];

    function handleOutsideClick(event) {
      if (!refContainer.current.contains(event.target)) {
        refInput.current.value = "";
        setInputState("");
        setIsOpened(false);
      }
    }

    events.forEach((event) => {
      html.addEventListener(event, handleOutsideClick);
    });

    return () => {
      events.forEach((event) => {
        html.removeEventListener(event, handleOutsideClick);
      });
    };
  }, []);

  return (
    <div>
      <div
        className={`${styles.container} ${isOpened ? "isOpened" : ""} ${isFilled ? "isFilled" : ""} ${
          error ? "isInvalid" : ""
        } ${isDisabled ? "isDisabled" : ""}`}
        ref={refContainer}
      >
        <div className={styles.header}>
          <input
            autoComplete="off"
            id={id}
            type="text"
            placeholder={placeholder}
            className={styles.input}
            onFocus={handleFocus}
            onBlur={onBlur}
            onInput={({ target }) => {
              setInputState(target.value);
              if (onInputChange) {
                onInputChange(target.value);
              }
            }}
            ref={refInput}
          />
          <button type="button" className={styles.arrowCollapseButton} onClick={toggleOpened} disabled={isDisabled}>
            <CaretDown weight="fill" className={styles.arrowCollapse} />
          </button>
          {!isOpened && selectedOptions?.length > 0 && (
            <span className={styles.countIndicator}>
              {selectedOptions.length === 1
                ? `${selectedOptions.length} item selecionado`
                : `${selectedOptions.length} itens selecionados`}
            </span>
          )}
        </div>
        <div className={styles.collapseContainer}>
          {!isLoading ? (
            <>
              <div className={styles.list}>
                <ul ref={refListOfUnselected}>
                  {options
                    .filter((option) => {
                      return !(
                        selectedOptions &&
                        selectedOptions.some((item) => JSON.stringify(item) === JSON.stringify(option))
                      );
                    })
                    .filter((option) => {
                      if (inputState === "") return true;
                      else return option.label.toLowerCase().includes(inputState.toLowerCase());
                    })
                    .map((option, index) => {
                      const value = () => {
                        return JSON.stringify(option);
                      };
                      const label = () => {
                        return { __html: option.label };
                      };

                      return (
                        <li key={index}>
                          <label className={`${styles.labelOption} ${styles.leftLabelOption}`}>
                            <input type="checkbox" value={value()} onChange={toggleActiveClass} />
                            <div dangerouslySetInnerHTML={label()} />
                          </label>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className={styles.actionList}>
                <button type="button" onClick={sendToRight} disabled={isDisabled}>
                  <CaretRight weight="fill" />
                </button>
                <button type="button" onClick={sendToLeft} disabled={isDisabled}>
                  <CaretLeft weight="fill" />
                </button>
                <button type="button" onClick={toggleOptions} disabled={isDisabled}>
                  <ArrowsLeftRight weight="fill" />
                </button>
              </div>
              <div className={styles.list}>
                <ul ref={refListOfSelected}>
                  {selectedOptions &&
                    selectedOptions
                      .filter((option) => {
                        if (inputState === "") return true;
                        else return option.label.toLowerCase().includes(inputState.toLowerCase());
                      })
                      .map((item, index) => {
                        const value = () => {
                          return JSON.stringify(item);
                        };
                        const label = () => {
                          return { __html: item.label };
                        };

                        return (
                          <li key={index}>
                            <label className={`${styles.labelOption} ${styles.rightLabelOption}`}>
                              <input type="checkbox" value={value()} onChange={toggleActiveClass} />
                              <div dangerouslySetInnerHTML={label()} />
                            </label>
                          </li>
                        );
                      })}
                </ul>
              </div>
            </>
          ) : (
            <div className={styles.loadingContainer}>
              <div className={styles.spinner}>
                <div className={styles.inside}>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {error && !isDisabled ? <p className={styles.error}>{error}</p> : null}
    </div>
  );
}
